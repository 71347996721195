import axios from "axios";
import { baseURL, headers } from "../config/config";

export const getJobs = async (status, page = 0, limit = 40) => {

    var url = `${baseURL}/jobs/list?status=${status}&page=${page}&limit=${limit}`;

    try {
        const response = await axios.get(url, headers);

        return response.data;
    } catch (error) {
        console.error('Error fetching orders:', error);
        throw error; // Re-throwing the error for handling in the calling code
    }
}
