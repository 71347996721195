import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCustomerByPhoneApi } from "../../Apis/Customer";
import { getCardData, getOrderWiseReport, refreshData } from "../../Apis/Dashboard";
import { getOrders, orderCsvApi } from "../../Apis/orders";
import ExportComponent from "./ExportComponent";
import "./Orders.css";
import SingleOrderCard from "./SingleOrderCard2";
import { debounce } from "lodash";
import axios from "axios";
import { baseURL } from "../../config/config";
import moment from "moment";

const Orders = () => {
  const [searchDisable, searchDisable_] = useState(false);
  const [orders, setOrders] = useState("all");
  const [data, setData] = useState();

  const today = new Date();
  today.setDate(today.getDate() - 7); // Subtract 7 days from today's date

  const sevenDaysAgo = new Date(today); // Create a new Date object with the date 7 days ago
  const formattedSevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0];

  const [toDate, setToDate] = useState(new Date().toISOString().split("T")[0]);
  const [fromDate, setFromDate] = useState(formattedSevenDaysAgo);

  const [selectedDateOption, setSelectedDateOption] = useState();
  const [orderCount, setOrderCount] = useState();
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [noMoreOrders, setNoMoreOrders] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [disableButtons, setDisableButtons] = useState(true);
  const [disableExport, setDisableExport] = useState(false);

  const navigate = useNavigate();

  const handleButtonClick = (orderType) => {
    setOrders(orderType);
  };

  const getOrderCount = async () => {
    try {
      const response = await getOrderWiseReport();
      if (response) {
        let temp = response.orderCount;
        let allOrders = 0;
        temp.forEach((a) => {
          allOrders += a.count;
        });
        temp.push({
          _id: "all_orders",
          count: allOrders,
        });
        temp.sort((a, b) => {
          return a._id.localeCompare(b._id);
        });
        setOrderCount(temp);
        console.log(temp);
      }
    } catch (error) {
      console.error("Error fetching order count:", error);
    }
  };

  useEffect(() => {
    let perm = localStorage.getItem("permissions");
    if (perm) {
      perm = JSON.parse(perm);
      if (
        perm.some(
          (permission) =>
            (permission.name === "orders" && permission.read === true) ||
            permission.name === "admin"
        )
      ) {
        if (
          perm.some(
            (permission) =>
              (permission.name === "orders" && permission.modify === true) ||
              permission.name === "admin"
          )
        )
          setDisableButtons(false);
        else setDisableButtons(true);
      } else {
        navigate("/404");
      }
    }
    getOrderCount().then();
  }, []);

  const loadMoreOrders = async () => {
    if (loadingOrders || !data) return;
    setLoadingOrders(true);
    try {
      const response = await getOrders(orders, fromDate, toDate, "", currentPageNumber);
      setCurrentPageNumber(currentPageNumber + 1);
      if (response.orders === undefined) {
        setNoMoreOrders(true);
      } else {
        setData((prevData) => ({
          ...prevData,
          orders: [...prevData.orders, ...response.orders],
        }));
      }
    } catch (error) {
      console.error("Error loading more orders:", error);
    }
    setLoadingOrders(false);
  };
  const handleScroll = debounce(() => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    if (windowHeight + scrollTop >= documentHeight - 200 && !noMoreOrders && !loadingOrders) {
      loadMoreOrders();
    }
  }, 300);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const fetchData = async () => {
    setNoMoreOrders(false);
    setData(null);
    setLoadingOrders(true);
    const requestTimestamp = Date.now();
    try {
      const result = await getOrders(orders, fromDate, toDate, "");
      if (Math.abs(requestTimestamp - latestRequestTimestamp.current) <= 50) {
        if (!result.success) {
          setNoMoreOrders(true);
        } else {
          setData(result);
          setCurrentPageNumber(2);
        }
      } else {
        console.log("validation failed", result);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }

    getOrderCount().then();

    setLoadingOrders(false);
  };

  const getCardDataSet = async () => {
    try {
      const res = await getCardData(
        moment(new Date()).format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD")
      );
      if (res) {
        const orderCount = res?.data?.report?.orderCount + res?.data?.report?.cancelledOrderCount;
        console.log(orderCount);
        if (!localStorage.getItem("orderCount"))
          localStorage.setItem("orderCount", JSON.stringify(orderCount));
        else {
          if (JSON.stringify(orderCount) !== localStorage.getItem("orderCount")) {
            localStorage.setItem("orderCount", JSON.stringify(orderCount));
            const audio = new Audio(require("../../assets/audio/alert.wav"));
            audio.play().then(() => {
              alert("Please reload.");
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!localStorage.getItem("mute")) {
      localStorage.removeItem("orderCount");
      const interval = setInterval(() => {
        refreshData().then(() => {
          getCardDataSet().then();
        });
      }, 5000);
      return () => clearInterval(interval);
    }
  }, []);

  // Define a ref to store the timestamp of the latest request
  const latestRequestTimestamp = useRef(null);
  useEffect(() => {
    latestRequestTimestamp.current = Date.now();
    setCurrentPageNumber(0);
    setData([]);
    fetchData().then();
  }, [orders, toDate, fromDate]);

  const handlePhoneNumber = async () => {
    if (PhoneNumber.length === 10) {
      try {
        const res = await getCustomerByPhoneApi(PhoneNumber);
        if (res && res.users && res.users._id) navigate(`/customerdetails?id=${res.users._id}`);
        else {
          alert("Not found.");
        }
      } catch (error) {
        console.log(error);
        alert("Not found.");
      }
    } else navigate(`/orderdetails?id=${PhoneNumber}`);
  };

  const downloadCsv = async () => {
    try {
      console.log(orders, fromDate, toDate);
      setDisableExport(true);
      const res = await orderCsvApi(orders, fromDate, toDate);
      if (res) {
        const blob = new Blob([res], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "orders.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
      setDisableExport(false);
    } catch (error) {
      console.log(error, "at Customer.js");
    }
  };
  return (
    <div>
      <div id="FilterOrdersDiv">
        <div id="filterNav">
          <input
            className="searchOrder"
            onKeyDownCapture={(e) => {
              if (e.nativeEvent.key === "Enter") {
                handlePhoneNumber();
              }
            }}
            placeholder="Search by Mobile or order ID"
            type="text"
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <button
            onClick={handlePhoneNumber}
            style={{
              cursor: !searchDisable ? "pointer" : "default",
              backgroundColor: searchDisable ? "#ddd" : "#ffef03",
              color: searchDisable ? "#aaa" : "#000",
              borderWidth: searchDisable ? 0 : 1,
              padding: 10,
              fontSize: 14,
              flex: 1,
              borderRadius: 10,
              borderStyle: "solid",
              borderColor: "#e3d400",
              overflow: "hidden",
              textAlign: "center",
              alignItems: "center",
              maxWidth: 200,
            }}
          >
            Search
          </button>
        </div>
        <div id="dateNav">
          <div
            style={{
              display: "flex",
              gap: 20,
              width: "60%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 0.2,
                flexDirection: "column",
                display: "flex",
                paddingLeft: 5,
                paddingRight: 5,
                border: "2px solid #e6e6e6",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                paddingBottom: 5,
              }}
            >
              <label
                style={{
                  textAlign: "center",
                  fontSize: 12,
                  justifyContent: "flex-start",
                }}
              >
                Start Date
              </label>
              <input
                style={{
                  borderWidth: 0,
                  outlineWidth: 1,
                  padding: 0,
                  textAlign: "center",
                }}
                type="date"
                value={fromDate}
                label="Start Date"
                onChange={(e) => {
                  setFromDate(e.target.value);
                }}
                className="date-picker-input"
                placeholder="Start date"
              />
            </div>
            <div
              style={{
                flex: 0.2,
                flexDirection: "column",
                display: "flex",
                border: "2px solid #e6e6e6",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                paddingLeft: 5,
                paddingRight: 5,
                paddingBottom: 5,
              }}
            >
              <label
                style={{
                  textAlign: "center",
                  fontSize: 12,
                  justifyContent: "flex-start",
                }}
              >
                End Date
              </label>
              <input
                style={{
                  borderWidth: 0,
                  padding: 0,
                  outlineWidth: 0,
                  textAlign: "center",
                }}
                type="date"
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                }}
                className="date-picker-input"
                placeholder="End date"
              />
            </div>
          </div>
          <div>
            <button className="ExportButton" disabled={disableExport} onClick={downloadCsv}>
              EXPORT
            </button>
          </div>
        </div>
      </div>

      <div id="OrdersListDiv">
        <div className="orderNav">
          <h5>All Orders</h5>
          <div className="orderNavButtons">
            <button
              style={{ width: 140 }}
              className={orders === "all" ? "active" : ""}
              onClick={() => handleButtonClick("all")}
            >
              All orders ({orderCount && orderCount[0]?.count})
            </button>
            <button
              style={{ width: 140 }}
              className={orders === "pending" ? "active" : ""}
              onClick={() => handleButtonClick("pending")}
            >
              New orders ({orderCount && orderCount[5]?.count})
            </button>
            <button
              style={{ width: 140 }}
              className={orders === "confirmed" ? "active" : ""}
              onClick={() => handleButtonClick("confirmed")}
            >
              Checking ({orderCount && orderCount[2]?.count})
            </button>
            <button
              style={{ width: 140 }}
              className={orders === "processed" ? "active" : ""}
              onClick={() => handleButtonClick("processed")}
            >
              Processed ({orderCount && orderCount[6]?.count})
            </button>
            <button
              style={{ width: 140 }}
              className={orders === "dispatched" ? "active" : ""}
              onClick={() => handleButtonClick("dispatched")}
            >
              Dispatched ({orderCount && orderCount[4]?.count})
            </button>
            <button
              style={{ width: 140 }}
              className={orders === "delivered" ? "active" : ""}
              onClick={() => handleButtonClick("delivered")}
            >
              Delivered ({orderCount && orderCount[3]?.count})
            </button>
            <button
              style={{ width: 140 }}
              className={orders === "cancelled" ? "active" : ""}
              onClick={() => handleButtonClick("cancelled")}
            >
              Cancelled ({orderCount && orderCount[1]?.count})
            </button>
          </div>
        </div>
        <div></div>

        {/* <table>
          <thead>
            <tr className="headerRow">
              <th>Sr</th>
              <th>Order ID</th>
              <th>Date</th>
              <th>Customer Details</th>
              <th>Transaction Details</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table> */}
        {data &&
          data.orders &&
          data.orders.length > 0 &&
          data.orders.map((item, index) => (
            <SingleOrderCard
              disableButtons={disableButtons}
              key={index}
              setCurrentPageNumber={setCurrentPageNumber}
              setData={setData}
              latestRequestTimestamp={latestRequestTimestamp}
              fetchData={fetchData}
              item={item}
              index={index}
            />
          ))}
        {noMoreOrders && <p style={{ textAlign: "center" }}>❌No More Orders</p>}
        {loadingOrders && <div className="loader"></div>}
      </div>
    </div>
  );
};

export default Orders;
