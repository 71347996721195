import axios from "axios";
const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxN2QyOTgyYmQ2OGM5NGQwYmNiOTIwMCIsInBob25lTm8iOjg3ODc4Nzg3ODcsImVtYWlsIjoiYWFwa2FiYXphcnNlbGxlckBnbWFpbC5jb20iLCJpYXQiOjE3MDc0ODkwMzN9.NziQ8vjNz5y42pFtsq6739vo7GwOVuOzVsX0hcFrq0Q";

export const getData = async url => {
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (err) {
        console.log("Error in getDataSec: " + url, err);
        return false;
    }
};

export const postData = async (url, data) => {
    try {
        const response = await axios.post(url, data);
        return response.data;
    } catch (err) {
        console.log("Error in postDataSec: " + url, err);
        return false;
    }
};

export const getDataSec = async url => {
    try {
        const response = await axios.get(url, {
            headers: {
                'x-access-token': token,
            },
        });
        return response.data;
    } catch (err) {
        console.log("Error in getDataSec: " + url, err);
        return false;
    }
};

export const postDataSec = async (url, data) => {
    try {
        const response = await axios.post(url, data, {
            headers: {
                'x-access-token': token,
            },
        });
        return response.data;
    } catch (err) {
        console.log("Error in postDataSec: " + url, err);
        return false;
    }
};
