import { baseURL, liveURL } from "../../config/config";
import { useState, useEffect } from "react";

export const EditOrderDetailsProductCard = ({ item, index, isEditable, products, setProducts }) => {
    const handleQuantityChange = (e, idx) => {
        const updatedProducts = [...products];
        updatedProducts[idx].quantity = e.target.valueAsNumber;
        setProducts(updatedProducts);
    };

    const toggleStockStatus = (idx) => {
        const updatedProducts = [...products];
        updatedProducts[idx].quantity = updatedProducts[idx].quantity > 0 ? 0 : 1;
        setProducts(updatedProducts);
    };

    return (
        <tr key={index} style={{ opacity: products[index].quantity === 0 ? 0.2 : 1 }}>
            <td>
                <span>{index + 1}</span>
            </td>
            <td>
                <div style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column", gap: 5 }}>
                    <p className="blacktext">SKU</p>
                    <p className="greytext">{products[index].sku}</p>
                    <p className="blacktext">GSTIN</p>
                    <p className="greytext">{products[index].hsnCode}</p>
                </div>
            </td>
            <td>
                <div style={{ display: "flex", gap: 10 }}>
{
products[index]?.images?.length>0&&
                    <div>
                        <img src={`${liveURL}/public/product/${products[index].id}/${products[index]?.images[0]}`} className="productImage" />
                    </div>
}
                    <div>
                        <p className="blacktext">{products[index].name}</p>
                        <p className="greytext">{products[index].recommendedAttribute}</p>
                    </div>
                </div>
            </td>
            <td>
                <span>{products[index].price}</span>
            </td>
            <td>
                <span>{!products[index]?.newlyAdded ?
                    products[index].sellPrice :
                    products[index].quantity > products[index].minQuantity
                        ? products[index].sellPrice
                        : products[index].minSellPrice}
                </span>
            </td>
            <td>
                <input
                    type="number"
                    style={{ width: 40 }}
                    disabled={!isEditable}
                    value={products[index].quantity}
                    onChange={(e) => handleQuantityChange(e, index)}
                />
            </td>
            <td>
                <span>{products[index].quantity * (!products[index]?.newlyAdded ?
                    products[index].sellPrice :
                    products[index].quantity > products[index].minQuantity
                        ? products[index].sellPrice
                        : products[index].minSellPrice
                )}</span>
            </td>
            {isEditable && (
                <td>
                    {products[index].quantity > 0 ? (
                        <button onClick={() => toggleStockStatus(index)}>Out of Stock</button>
                    ) : (
                        <button onClick={() => toggleStockStatus(index)}>Add Back</button>
                    )}
                </td>
            )}
        </tr>
    );
};
